import React, { useCallback, useEffect, useMemo } from 'react'
import { hooks } from '@front/volcanion'

import { Loader } from '@front/squirtle'

import Callbacks from './callbacks'
import Memos from './memos'
import PopupCallbacks from './Popup/callbacks'
import { HookUtils } from '@front/volcanion/utils/'
import OnKeyDown from './onKeyDown'

const withContainer = Component => props => {
  const { setIsWaiting, isWaiting } = props

  const isReady = hooks.useFormStatus('isReady')
  const isSubmitting = hooks.useFormStatus('isSubmitting')

  const setFormValues = hooks.useFormChange()
  const navigate = hooks.useNavigate()
  const [, { openDialog }] = hooks.useDialogs()
  const { openNotification, closeNotification } = hooks.useNotification()
  const reset = hooks.useFormReset()
  const requestSubmit = hooks.useFormFunction('requestSubmit')
  const [meta] = hooks.useFormMeta()
  const [operator] = hooks.useActiveUser({ populate: ['auth', 'info'] })

  const { EXTERNAL_PHONE_SERVICE } = hooks.useRelayConstants(['EXTERNAL_PHONE_SERVICE'])
  const B2B_ENABLED = hooks.useRelayFeature('B2B_ENABLED')

  const [{
    record_id,
    hasCompanyAccess,
    isManagingRecord,
    isB2B,
    isGrouped,
    isManagingBillingRecord,
    isMapOpen,
    isReadOnly,
    displayDownloadBillingButton
  }, setFormState] = hooks.useFormState()

  const [
    mode,
    ridemode_duration,
    gsm,
    commercial_package,
    companycontract,
    companyservice_id,
    schedule_type,
    destination,
    estimation_type,
  ] = hooks.useFieldValues(['ridemode', 'ridemode_duration', 'user.gsm', 'commercial_package', 'user.companycontract', 'user.companyservice', 'schedule_type', 'destination', 'estimation_type'])

  const [order] = hooks.useModel('order', [record_id], { single: true, populate: ['assigned_transport.driver.info', 'assigned_transport.driver.auth', 'info', 'do.auth', 'loads'] })
  const [ridemode] = hooks.useModel('ridemode', [mode], { single: true, populate: ['config'] })
  const [companyservice] = hooks.useModel('companyservice', [companyservice_id], { single: true })

  const estimation_status = hooks.useEstimationStatus()
  const [selectedEstimation] = hooks.useSelectedEstimation(commercial_package, estimation_type)
  const [eventGenerate] = hooks.useModelFunction('event', 'generate')
  const [billingAction] = hooks.useModelFunction('order', 'billingAction')
  const [cancel] = hooks.useModelFunction('order', 'cancel')
  const [downloadInvoice] = hooks.useOrderInvoice(record_id)
  const [isDownloadAvailable] = hooks.useModelFunction('order', 'isDownloadAvailable')

  const createdAt = order?.createdAt
  const driver_number = order?.assigned_transport?.driver?.auth?.gsm
  const driver_id = order?.assigned_transport?.driver?.user_id
  const driver_client_id = order?.assigned_transport?.driver?.info?.user_client_id
  const status = order?.status
  const canCancel = !_.includes(['complete', 'canceled'], status)
  const customer_number = order?.loads[0]?.src_phone || order?.do?.auth?.gsm
  const call_id = order?.info?.call_id
  const destination_enabled = !!(isManagingRecord && isReadOnly) ? !!destination : ridemode?.config?.display_destination_field
  const duration_enabled = !!(isManagingRecord && isReadOnly) ? !!ridemode_duration : !!ridemode?.duration_required && !ridemode?.config?.display_destination_field
  const focusedField = _.find(meta, ['active', true])
  const hasPasswordIncorrect = !!isB2B && !hasCompanyAccess && !!companyservice_id


  const onSubmitHandler = useCallback(Callbacks.onSubmitHandler(hasPasswordIncorrect, requestSubmit, openNotification), [hasPasswordIncorrect, requestSubmit, openNotification])
  const refuse = useCallback(() => billingAction({ order_id: record_id, action: 'refuse' }), [record_id])
  const approve = useCallback(() => billingAction({ order_id: record_id, action: 'approve' }), [record_id])
  const handleChangeIsB2B = useCallback(() => !!B2B_ENABLED && setFormState({ isB2B: !isB2B }), [B2B_ENABLED, isB2B, setFormState])

  const handleOpenCancelConfirmationDialog = useCallback(PopupCallbacks.handleOpenCancelConfirmationDialogHandler(openDialog, record_id), [openDialog, record_id])
  const handleInvoiceDownload = useCallback(Callbacks.handleInvoiceDownloadHanlder(downloadInvoice, openNotification), [downloadInvoice, openNotification])
  const isDownloadAvailableStatus = useCallback(Callbacks.isDownloadAvailableStatusHandler(isDownloadAvailable, setFormState), [isDownloadAvailable, setFormState])
  const handleCancel = useCallback(Callbacks.handleCancelHandler(cancel, order, openNotification, handleOpenCancelConfirmationDialog, isGrouped), [cancel, order, openNotification, handleOpenCancelConfirmationDialog, isGrouped])
  const handleClickCallCenter = useCallback(Callbacks.handleClickCallCenterHandler(reset, setIsWaiting), [reset, setIsWaiting])
  const handleClickMap = useCallback(Callbacks.handleClickMapHandler(isMapOpen, setFormState), [isMapOpen, setFormState])
  const handleNewOrder = useCallback(Callbacks.handleNewOrderHandler(navigate), [navigate])
  const handleValidateNotification = useCallback(Callbacks.handleValidateNotificationHandler(openNotification, closeNotification, setFormValues), [openNotification, closeNotification, setFormValues])

  const canOrangeWait = useMemo(() => Memos.handleCanOrangeWait(schedule_type, createdAt, call_id, EXTERNAL_PHONE_SERVICE), [schedule_type, createdAt, call_id, EXTERNAL_PHONE_SERVICE])
  const handleWaitingOrange = useCallback(Callbacks.handleWaitingOrangeHandler(), [])
  const handleOrangeCall = useCallback(Callbacks.handleOrangeCallHandler(), [])
  const handleCall = useCallback(Callbacks.handleCallHandler(EXTERNAL_PHONE_SERVICE, handleOrangeCall), [EXTERNAL_PHONE_SERVICE, handleOrangeCall])
  const handleOpenSendCommentDialog = useCallback(PopupCallbacks.handleOpenSendCommentDialogHandler(openDialog), [openDialog])
  const handleOpenManualDispatch = useCallback(PopupCallbacks.handleOpenManualDispatchHandler(openDialog, order, handleCall), [openDialog, order, handleCall])
  const handleOpenSendMessageDriver = useCallback(PopupCallbacks.handleOpenSendMessageDriverHandler(openDialog), [openDialog])
  const handleOpenRedispatch = useCallback(PopupCallbacks.handleOpenRedispatchHandler(openDialog, status, driver_client_id), [openDialog, status, driver_client_id])
  const handleOpenClaimRedispatch = useCallback(PopupCallbacks.handleOpenClaimRedispatchHandler(openDialog, eventGenerate, order), [openDialog, eventGenerate, order])
  const handleOpenShortcut = useCallback(PopupCallbacks.handleOpenShortcutHandler(openDialog), [openDialog])

  const keyDownState = {
    isReadOnly,
    isMapOpen,
    isManagingBillingRecord,
    isManagingRecord,
    canOrangeWait,
    driver_number,
    customer_number,
    gsm,
    operator,
    focusedField,
    companycontract,
    companyservice
  }
  const keyDownFunctions = {
    refuse,
    approve,
    onSubmitHandler,
    handleOpenShortcut,
    handleCancel,
    handleCall,
    handleWaitingOrange,
    handleOpenSendCommentDialog,
    handleOpenManualDispatch,
    handleOpenSendMessageDriver,
    handleOpenRedispatch,
    handleOpenClaimRedispatch,
    handleClickMap,
    handleChangeIsB2B,
    navigate,
    openNotification,
    setFormValues,
    setFormState
  }

  const ctrlKeyMap = useCallback(OnKeyDown.ctrlKeyMapHandler(order, keyDownState, keyDownFunctions), [order, keyDownState, keyDownFunctions])
  const altKeyMap = useCallback(OnKeyDown.altKeyMapHandler(order, keyDownState, keyDownFunctions), [order, keyDownState, keyDownFunctions])
  const baseKeyMap = useCallback(OnKeyDown.baseKeyMapHandler(order, keyDownState, keyDownFunctions), [order, keyDownState, keyDownFunctions])
  const onKeyDown = useCallback(OnKeyDown.onKeyDownHandler(ctrlKeyMap, altKeyMap, baseKeyMap), [ctrlKeyMap, altKeyMap, baseKeyMap])

  const isEstimationLoading = HookUtils.getLoadingState([estimation_status])

  useEffect(() => {
    !isEstimationLoading && handleValidateNotification(selectedEstimation?.validation?.errors)
  }, [isEstimationLoading])

  useEffect(() => {
    if (!isWaiting && !!isReady) {
      setFormValues([{ field: 'user.gsm', meta: { focus: true } }])
    }
  }, [isWaiting])

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])

  useEffect(() => {
    if (!!record_id)
      isDownloadAvailableStatus(record_id)

  }, [record_id])

  const disabledSubmitButton = !!HookUtils.getLoadingState(estimation_status)

  const mergedProps = {
    disabledSubmitButton,
    destination_enabled,
    duration_enabled,
    commercial_package,
    driver_id,
    record_id,
    canOrangeWait,
    canCancel,
    isSubmitting,
    isManagingRecord,
    isReadOnly,
    isEstimationLoading: estimation_status?.isLoading,
    displayDownloadBillingButton,
    handleClickMap,
    handleClickCallCenter,
    handleWaitingOrange,
    handleOpenSendCommentDialog,
    handleOpenManualDispatch,
    handleOpenSendMessageDriver,
    handleOpenRedispatch,
    handleOpenClaimRedispatch,
    handleNewOrder,
    onSubmitHandler,
    handleCancel,
    handleInvoiceDownload
  }

  return (
    <Loader isLoading={!isReady}>
      <Component {...mergedProps} {...props} />
    </Loader>
  )
}

export default withContainer
