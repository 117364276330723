import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent, Button, Link } from '@mui/material'

import { TextField, ModelMenuSelectorField, MenuSelectorField, DateField, ConfirmationDialog } from '@front/squirtle'
import { isRequired } from '@front/squirtle/utils/validators'

import SuspensionDialog from '@abra/elements/Popup/Suspension'

const Row1 = ({ isManagingRecord, color, statusChange, group_id, usual_vehicle, vehicle_id, verifyClientId }) =>
  <Grid container spacing={2}>
    <Grid item xs>
      <TextField
        label={I18n.t('contract.id')}
        name={'license_client_id'}
        required
        afterUserChange={verifyClientId}
        debounceTime={250}
      />
    </Grid>
    <Grid item xs>
      <TextField
        label={I18n.t('contract.license_id')}
        name={'license_number'}
        required
        validate={isRequired}
      />
    </Grid>
    <Grid item xs>
      <DateField
        label={I18n.t('date.delivery')}
        name={'delivery_date'}
        inputFormat="DD/MM/YYYY"
        required
      />
    </Grid>
    <Grid item xs>
      <ModelMenuSelectorField
        label={I18n.t('category.label', { count: 1 })}
        name={'billing_category'}
        model_name={'billingcategory'}
        labelKeys={['name_translated']}
        searchMode={'search'}
        loadOnMount
        multiple={false}
        validate={isRequired}
        required
      />
    </Grid>
    <Grid item xs>
      <MenuSelectorField
        label={I18n.t('status.label', { count: 1 })}
        name={'status'}
        options={_.compact([
          { label: I18n.t('status.active'), value: 'active' },
          { label: I18n.t('status.deleted'), value: 'deleted', disabled: !isManagingRecord },
          { label: I18n.t('status.suspended'), value: 'suspended', disabled: !isManagingRecord }
        ])}
        valueDecoratorProps={{
          sx: { WebkitTextFillColor: color, fontWeight: 600 }
        }}
        selectFirst
        required
        afterChange={statusChange}
      />
    </Grid>
    <Grid item xs>
      <Typography sx={{ fontSize: 13 }}> {I18n.t('vehicle.usual')} </Typography>
      <Typography>
        {!!usual_vehicle ?
          <Link href={`/adherent/${group_id}/vehicle/${vehicle_id}`} target={'_blank'}>
            {usual_vehicle}
          </Link>
          : '-'
        }
      </Typography>
    </Grid>
  </Grid>

const PrimarySection = (props) => {
  const {
    isManagingRecord,
    formattedSuspension,
    suspendedStateName,
    initialSuspendedValues,
    displayRemoveSuspend,
    onClickRemoveSuspend,
    onConfirmRemoveSuspend
  } = props || {}

  return (<>
    <SuspensionDialog
      form_name='license'
      enable_motive={true}
      suspendedStateName={suspendedStateName}
      initialValues={initialSuspendedValues}
    />
    <ConfirmationDialog
      name={'remove_license_suspend'}
      onConfirm={onConfirmRemoveSuspend}
    />
    <StepLabel>
      <Box sx={{ display: 'flex', alignItems: 'center', fontSize: 'h6.fontSize', color: 'text.title' }}>
        <Typography variant='h5'> {I18n.t('contract.label', { count: 1 })} </Typography>
        <Typography sx={{ ml: 1, mt: '5px', fontSize: 'subtitle2.fontSize', color: 'red' }} > {formattedSuspension}</Typography>
        {!!displayRemoveSuspend &&
          <Button size='small' variant='outlined' onClick={onClickRemoveSuspend}>
            {I18n.t('suspend.stop.suspend')}
          </Button>}
      </Box>
    </StepLabel>
    <StepContent >
      <Box sx={{ p: 2, boxShadow: 3, borderRadius: 1 }}>
        <Row1 isManagingRecord={isManagingRecord} {...props} />
      </Box>
    </StepContent>
  </>)
}

export default React.memo(PrimarySection)
