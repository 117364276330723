import React from 'react'
import { hooks } from '@front/volcanion'

import moment from 'moment'
import { FormatUtils, TimeUtils } from '@front/volcanion/utils'

const withContainer = Component => ({ record_id, ...props }) => {
  const [record_form_id] = hooks.useFormState('record_id')
  const [isMapOpen, setIsMapOpen] = hooks.useFormState('isMapOpen')
  const [order] = hooks.useModel('order', [record_form_id || record_id], {
    populate: [
      'assigned_transport.vehicle.info.model.brand',
      'assigned_transport.info',
      'current_target.eta',
      'quotation'
    ],
    single: true
  })

  const vehicle = _.get(order, 'assigned_transport.vehicle')

  const vehicle_info = _.join(_.compact([_.get(vehicle, 'info.plaque'), _.get(vehicle, 'info.model.name'), _.get(vehicle, 'info.model.brand.name')]), ' ')

  const time = TimeUtils.secondsToTime(
    !!order?.current_target?.eta?.time
      ? moment(order?.current_target?.eta?.time).diff(moment(), 'seconds')
      : moment(order?.assigned_transport?.info?.finalizedAt).diff(moment(order?.assigned_transport?.info?.arrivedAt), 'seconds')
    , 'HH[h]mm[min]')

  const mergedProps = {
    isMapOpen,
    setIsMapOpen,
    vehicle_info,
    distance: FormatUtils.distance(order?.current_target?.eta?.distance || order?.quotation?.distance),
    time
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer
