import React from 'react'
import { I18n } from '@front/volcanion'

import { IconButton, Collapse, Stack, Chip, Icon, Typography } from '@mui/material'

import MapComponent from './MapRender'

const MapSection = ({
  distance,
  time,
  vehicle_info,
  isMapOpen,
  setIsMapOpen,
  disableExpend = false,
  disableTitle = false,
}) => <>
    <Stack direction='row' spacing={1} alignItems='center'>
      {!disableExpend && <IconButton onClick={() => setIsMapOpen(!isMapOpen)}>
        <Icon>{!!isMapOpen ? 'expand_less' : 'expand_more'}</Icon>
      </IconButton>}
      {!disableTitle && <Typography variant='h5'>{I18n.t('incident.title.map')}</Typography>}
      <Chip icon={<Icon>{'route'}</Icon>} label={distance} variant='outlined' color='primary' />
      <Chip icon={<Icon>{'access_time'}</Icon>} label={time} variant='outlined' color='primary' />
      <Chip icon={<Icon>{'directions_car'}</Icon>} label={vehicle_info || '-'} variant='outlined' color='primary' />
    </Stack>
    <Collapse in>
      <MapComponent />
    </Collapse>
  </>


export default React.memo(MapSection)

